import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "firebase/firestore";

import Presentation from "./Presentation";
import Admin from "./Admin";
import Login from "./Login";
import Accounting from "./Accounting";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/presentations" element={<Presentation />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/accounting" element={<Accounting />} />
      </Routes>
    </Router>
  );
}

export default App;
