import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/tailwind.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//Firebase stuff
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCVXSr_FElX5JEm9sr1N_VFMLKZxkgYk7E",
  authDomain: "vjp-app-5a9b7.firebaseapp.com",
  projectId: "vjp-app-5a9b7",
  storageBucket: "vjp-app-5a9b7.appspot.com",
  messagingSenderId: "506372510773",
  appId: "1:506372510773:web:05a99764bd5a6641c3cd7c",
  measurementId: "G-VLJ9MS9NFS",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { app };
