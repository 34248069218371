import { useEffect, useState } from "react"
import { getFirestore, collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faCheck, faHardHat, faIdCard, faUser } from "@fortawesome/free-solid-svg-icons";

const Admin = () => {
    const [userData, setUserData] = useState([])
    const [defaultData, setDefaultData] = useState([])

    useEffect(() => {
        fetchUserData()
    }, [])

    const fetchUserData = async () => {
        const db = getFirestore();
        const userCollection = collection(db, "Users");
        const userSnapshot = await getDocs(userCollection);

        const users = userSnapshot.docs.map(doc => ({ 
            id: doc.id, 
            ...doc.data() 
        }));

        setUserData([...users]); 
        setDefaultData(JSON.parse(JSON.stringify(users)));
    }

    const changeRole = (index,newRole) => {
        setUserData((prev) => {
            prev[index].role = newRole
            return [...prev]
        })
    }

    const confirmChange = async (user) => {
        const db = getFirestore()
        const userRef = doc(db, "Users", user.id)
        await updateDoc(userRef, {
            role: user.role
        })

        fetchUserData()
    }

    const usersElements = userData.map((item, index) => {
        return <section className=" flex flex-row mx-[20vw]" key={index}>
            <div key={index} className=" flex flex-row px-5 shadow-md bg-[#EFEFEF] mt-4 h-12 items-center">
                <h2 className=" w-[20vw]">{item.first_name + ' ' + item.last_name}</h2>
                <h2 className=" w-[20vw]">{item.email}</h2>
                <h2 className=" w-[10vw]">{item.role}</h2>
                <button className=" mr-[1vw]" onClick={item.role === 'user' ? null : () => changeRole(index, 'user')}>
                    <FontAwesomeIcon 
                    icon={faUser} 
                    color={item.role === 'user' ? '#4fac32' : '#000000'}
                    />
                </button>

                <button className=" mr-[1vw]" onClick={item.role === 'contractor' ? null : () => changeRole(index, 'contractor')}>
                    <FontAwesomeIcon 
                    icon={faHardHat} 
                    color={item.role === 'contractor' ? '#4fac32' : '#000000'}
                    />
                </button>

                <button className=" mr-[1vw]" onClick={item.role === 'accountant' ? null : () => changeRole(index, 'accountant')}>
                    <FontAwesomeIcon 
                    icon={faChartLine} 
                    color={item.role === 'accountant' ? '#4fac32' : '#000000'}
                    />
                </button>

                <button className=" mr-[1vw]" onClick={item.role === 'admin' ? null : () => changeRole(index, 'admin')}>
                    <FontAwesomeIcon 
                    icon={faIdCard} 
                    color={item.role === 'admin' ? '#4fac32' : '#000000'}
                    />
                </button>
            </div>

            { userData[index].role !== defaultData[index].role &&
                <button onClick={() => confirmChange(item)} className=" flex flex-col items-center bg-[#4fac32] h-12 mt-4 px-2 shadow-md">
                    <FontAwesomeIcon className=" h-6" icon={faCheck} color="#FFFFFF"/>
                    <h3 className=" text-white text-sm font-bold">Potvrdiť</h3>
                </button>
            }
        </section>
    })

    return (
        <main className=" h-full w-full flex flex-col">
            <h1 className=" mt-5 text-3xl font-bold mx-auto">Účty</h1>

            <section className=" h-[80vh] w-full mt-5 overflow-auto">
                {usersElements}
            </section>
        </main>
    )
}

export default Admin;