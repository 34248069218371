import { getFirestore, collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Accounting = () => {

    const [orders, setOrders] = useState([])
    const [userNames, setUserNames] = useState({})
    const [assigned, setAssigned] = useState('')
    const [orderBy, setOrderBy] = useState('none')

    console.log(orderBy)

    const navigate = useNavigate()
    const location = useLocation()
    const { assignedBy } = location.state




    useEffect(() => {

        if(!assignedBy)
            navigate('/')
        _fetchContractorOrders()
    }, [])

    const _fetchContractorOrders = async () => {
        try {
        const db = getFirestore();
        const ordersCollection = collection(db, 'Orders')
        const q = query(ordersCollection, where('assignedContractor', '==', assignedBy))
        
        const querySnapshot = await getDocs(q)
        const fetchOrders = []
        
        //For ID to name conversion
        const userIDs = new Set()
        userIDs.add(assignedBy)

        querySnapshot.forEach((doc) => {
            //console.log(doc.data())
            fetchOrders.push({id: doc.id, ...doc.data(), price: parseInt(doc.data().price)})
            userIDs.add(doc.data().author)

        })

        //Resolving fetch from firebase
        const userPromises = Array.from(userIDs).map(id => getUserName(id))
        const userArray = await Promise.all(userPromises)
        console.log(userArray)

        const userObj = {};
        userArray.forEach(user => {
            userObj[user.key] = user.value
        })

        setUserNames(userObj)

        const updateOrders = fetchOrders.map(order => ({
            ...order,
            author: userObj[order.author] || "unknown",
        }))

        setOrders(updateOrders)

        setAssigned(userObj[assignedBy])

        } catch(err) {
            console.log(err)
        }
    }

    const getUserName = async (userID) => {
        let user = {}

        try {
            const db = getFirestore()
            const userRef = doc(db, "Users", userID)
            const snapshot = await getDoc(userRef)
            const name = snapshot.data().first_name + ' ' + snapshot.data().last_name
            user =  {key: userID, value: name}

        } catch (err) {
            console.log(err)
        }

        return user
    }



    const orderOrders = (field) => {
        const ascOrder = `${field}-ASC`;
        const descOrder = `${field}-DESC`;
    
        setOrderBy(orderBy === ascOrder ? descOrder : ascOrder);
    };

    const orderByFunction = (a,b) => {
        if(orderBy === 'none')
            return 0;
        
        const [field, direction] = orderBy.toLowerCase().split('-')
        let comparison
        
        //dates
        if(['created' , 'deadline', 'finished', 'paid'].includes(field)) 
            comparison = a[field]?.toDate().getTime() - b[field]?.toDate().getTime()
        else if(field === 'price')
            comparison = a[field] - b[field]
        else
            comparison = a[field].localeCompare(b[field])

        return direction === 'asc' ? -comparison : comparison
    }

    const orderElements = orders.sort(orderByFunction).map((item, index) => {

        const createdDate = item.created.toDate()
        const created = `${createdDate.getDate()}.${createdDate.getMonth() + 1}.${createdDate.getFullYear()}`

        const deadlineDate = item.deadline.toDate()
        const deadline = `${deadlineDate.getDate()}.${deadlineDate.getMonth() + 1}.${deadlineDate.getFullYear()}`

        let finished = '-'

        if(item.finished) {
            const finishedDate = item.finished.toDate()
            finished = `${finishedDate.getDate()}.${finishedDate.getMonth() + 1}.${finishedDate.getFullYear()}`
        }

        let paid = '-'

        if(item.paid) {
            const paidDate = item.paid.toDate()
            paid = `${paidDate.getDate()}.${paidDate.getMonth() + 1}.${paidDate.getFullYear()}`

        }

        return <section className= " flex flex-row shadow-md mx-[10vw] bg-[#EFEFEF] mt-4 h-12 items-center px-5" key={index}>
            <h2 className= " w-[15vw] overflow-hidden text-center">{item.title}</h2>
            <h2 className= " w-[20vw] overflow-hidden text-center">{item.author}</h2>
            <h2 className= " w-[10vw] overflow-hidden text-center">{created}</h2>
            <h2 className= " w-[10vw] overflow-hidden text-center">{deadline}</h2>
            <h2 className= " w-[10vw] overflow-hidden text-center">{finished}</h2>
            <h2 className= " w-[10vw] overflow-hidden text-center">{paid}</h2>
            <h2 className= " w-[10vw] overflow-hidden text-center">{item.status}</h2>
            <h2 className= " w-[10vw] overflow-hidden text-center">{item.price}</h2>
        </section>
    })

    return (
        <main className=" h-full w-full flex flex-col">
            <h1 className=" mx-auto mt-5 font-bold text-3xl">Zákazky používateľa {assigned}</h1>

            <section className=" h-[80vh] w-full mt-5 overflow-auto">
                <section className=" select-none flex flex-row shadow-md mx-[10vw] bg-[#e3ffc0] mt-4 h-12 items-center px-5">
                    <div className=" relative w-[15vw] overflow-hidden cursor-pointer" onClick={() => {orderOrders('TITLE')}}>
                        <h2 className="text-center">NÁZOV</h2>
                        {orderBy === "TITLE-ASC" && <FontAwesomeIcon className=" absolute top-[15%] left-[70%]" icon={faChevronUp} size={15} color="#000000"/>}
                        {orderBy === "TITLE-DESC" && <FontAwesomeIcon className=" absolute top-[15%] left-[70%]" icon={faChevronDown} size={15} color="#000000"/>}
                    </div>

                    <div className="w-[20vw] overflow-hidden cursor-pointer relative" onClick={() => {orderOrders('AUTHOR')}}>
                        <h2 className="text-center">AUTOR</h2>
                        {orderBy === "AUTHOR-ASC" && <FontAwesomeIcon className=" absolute top-[15%] left-[65%]" icon={faChevronUp} size={15} color="#000000"/>}
                        {orderBy === "AUTHOR-DESC" && <FontAwesomeIcon className=" absolute top-[15%] left-[65%]" icon={faChevronDown} size={15} color="#000000"/>}
                    </div>

                    <div className="w-[10vw] overflow-hidden cursor-pointer relative"  onClick={() => {orderOrders('CREATED')}}>
                        <h2 className="text-center">PRIDANÉ</h2>
                        {orderBy === "CREATED-ASC" && <FontAwesomeIcon className=" absolute top-[15%] left-[80%]" icon={faChevronUp} size={15} color="#000000"/>}
                        {orderBy === "CREATED-DESC" && <FontAwesomeIcon className=" absolute top-[15%] left-[80%]" icon={faChevronDown} size={15} color="#000000"/>}
                    </div>

                    <div className="w-[10vw] overflow-hidden cursor-pointer relative"  onClick={() => {orderOrders('DEADLINE')}}>
                        <h2 className="text-center">TERMÍN</h2>
                        {orderBy === "DEADLINE-ASC" && <FontAwesomeIcon className=" absolute top-[15%] left-[80%]" icon={faChevronUp} size={15} color="#000000"/>}
                        {orderBy === "DEADLINE-DESC" && <FontAwesomeIcon className=" absolute top-[15%] left-[80%]" icon={faChevronDown} size={15} color="#000000"/>}
                    </div>

                    <div className="w-[10vw] overflow-hidden cursor-pointer relative"  onClick={() => {orderOrders('FINISHED')}}>
                        <h2 className="text-center">UKONČENÉ</h2>
                        {orderBy === "FINISHED-ASC" && <FontAwesomeIcon className=" absolute top-[15%] left-[85%]" icon={faChevronUp} size={15} color="#000000"/>}
                        {orderBy === "FINISHED-DESC" && <FontAwesomeIcon className=" absolute top-[15%] left-[85%]" icon={faChevronDown} size={15} color="#000000"/>}
                    </div>

                    <div className="w-[10vw] overflow-hidden cursor-pointer relative"  onClick={() => {orderOrders('PAID')}}>
                        <h2 className="text-center">ZAPLATENÉ</h2>
                        {orderBy === "PAID-ASC" && <FontAwesomeIcon className=" absolute top-[15%] left-[85%]" icon={faChevronUp} size={15} color="#000000"/>}
                        {orderBy === "PAID-DESC" && <FontAwesomeIcon className=" absolute top-[15%] left-[85%]" icon={faChevronDown} size={15} color="#000000"/>}
                    </div>

                    <div className="w-[10vw] overflow-hidden cursor-pointer relative">
                        <h2 className="text-center">STAV</h2>
                    </div>

                    <div className="w-[10vw] overflow-hidden cursor-pointer relative"  onClick={() => {orderOrders('PRICE')}}>
                        <h2 className="text-center">CENA</h2>
                        {orderBy === "PRICE-ASC" && <FontAwesomeIcon className=" absolute top-[15%] left-[70%]" icon={faChevronUp} size={15} color="#000000"/>}
                        {orderBy === "PRICE-DESC" && <FontAwesomeIcon className=" absolute top-[15%] left-[70%]" icon={faChevronDown} size={15} color="#000000"/>}
                    </div>
                </section>
                {orderElements}
            </section>
        </main>
    )
}

export default Accounting;