import { useState } from "react"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const onLogin = () => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then( async (userCredential) => {
        const user = userCredential.user;
        console.log(user.uid)

        const db = getFirestore()
        const userDocRef = doc(db, "Users", user.uid)
        const docSnap = await getDoc(userDocRef)

        if(docSnap.data().role === 'admin')
          navigate('/admin')
        else
          navigate('/accounting', {state: {assignedBy: docSnap.data().assignedBy}})
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error)
      });
    }

    return (
        <main className=" h-full w-full flex flex-col">
            <h1 className=" mx-auto mt-5 text-3xl font-bold">Prihlásenie</h1>
            <p className=" mx-auto mt-2 text-xl italic">Na prihlásenie do tohto systému musíte byť zaregistrovaný ako administrátor</p>

            <section className=" mx-auto w-[20wv] flex flex-col ">
                <label className=" mt-5 ml-2 text-xl font-semibold">Email</label>
                <input 
                type="email"
                value={email} 
                onChange={handleEmailChange}
                className="  w-[20vw] border-solid border-2 px-2"
                />

                <label className=" mt-5 ml-2 text-xl font-semibold">Heslo</label>
                <input 
                type="password" 
                value={password}
                onChange={handlePasswordChange}
                className="  w-[20vw] border-solid border-2 px-2"
                />

                <button 
                onClick={onLogin}
                className=" mt-8 bg-[#4fac32] rounded-md h-10 text-white font-bold text-lg"
                >
                Prihlásiť sa
                </button>
            </section>
        </main>
    )
}

export default Login