import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { app } from "./index";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

const Presentation = () => {
  const urlParams = window.location.href.split("?")[1];
  const orderID = urlParams.split(":")[0];
  const presentationID = urlParams.split(":")[1];

  console.log(urlParams);

  const [presentation, setPresentation] = useState(null);
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const db = getFirestore(app);
    const storage = getStorage(app);

    const presentationRef = doc(
      db,
      "Orders",
      orderID,
      "Presentations",
      presentationID
    );

    getDoc(presentationRef).then(async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();

        const fetchSlidesUrl = [];

        for (const slide of data.slides) {
          const fileRef = ref(
            storage,
            "/presentations/" + presentationID + "/" + slide.fileName
          );
          const result = await getDownloadURL(fileRef);

          fetchSlidesUrl.push(result);
        }

        setImages(() => [...fetchSlidesUrl]);
        setPresentation(data);
      }
    });
  }, []);

  const prevImage = () => {
    if (currentImage !== 0)
      setCurrentImage((prev) => {
        return prev - 1;
      });
  };

  const nextImage = () => {
    if (currentImage < images.length - 1)
      setCurrentImage((prev) => {
        return prev + 1;
      });
  };

  const thumbnails = images.map((image, index) => {
    return (
      <img
        key={index}
        src={image}
        onClick={() => {
          setCurrentImage(index);
        }}
        className=" max-h-[20vh] max-w-[20vw] mr-[3vw] cursor-pointer"
      />
    );
  });

  return (
    <main className=" w-[100vw] h-[100vh] flex flex-col overflow-hidden ">
      {presentation ? (
        <article className=" mx-auto mt-5">
          <h1 className=" text-center text-2xl">{presentation.title}</h1>

          <section className=" flex flex-row mt-10 justify-center w-[80vw] ">
            <button onClick={prevImage}>
              <FontAwesomeIcon icon={faChevronLeft} className=" h-24" />
            </button>

            <img
              src={images[currentImage]}
              className=" max-w-[50vw] max-h-[50vh] mx-20"
            />

            <button onClick={nextImage}>
              <FontAwesomeIcon icon={faChevronRight} className=" h-24" />
            </button>
          </section>

          <section className=" flex flex-row justify-center mt-10">
            {thumbnails}
          </section>
        </article>
      ) : (
        <FontAwesomeIcon className=" h-44" icon={faSpinner} spin />
      )}
    </main>
  );
};

export default Presentation;
